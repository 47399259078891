






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsWorkflowMessageEmailCard from '@/modules/workflows/components/VsWorkflowMessageEmailCard/Index.vue'
import VsWorkflowMessageSmsCard from '@/modules/workflows/components/VsWorkflowMessageSmsCard/Index.vue'
import { Workflow, WorkflowMessage } from '@/api/consoleApi/dto/workflows.dto'
import { WorkflowsModule } from '@/store/modules/workflows'

@Component({
    name: 'VsWorkflowMessageCard',
    components: {
        VsListingCard,
        VsDropdownButton,
        VsWorkflowMessageEmailCard,
        VsWorkflowMessageSmsCard,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private workflowMessage!: WorkflowMessage

    @Prop({
        default: null,
        required: true,
    }) private workflow!: Workflow

    @Prop({
        default: true,
        required: false,
        type: Boolean,
    }) private isEditable!: boolean

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private isLast!: boolean

    hasContentErrors = false

    get hasTriggerErrors () {
        if (!this.prevMessage) return false
        return this.prevMessage.entity_type === 'sms' ? !this.availableSmsFrom.includes(this.workflowMessage.trigger.parameter.from) : !this.availableEmailFrom.includes(this.workflowMessage.trigger.parameter.from)
    }

    get hasErrors () {
        return !this.workflowMessage?.entity_id || this.hasTriggerErrors || this.hasContentErrors
    }

    get availableSmsFrom () {
        return [
            'sent',
            'delivered',
            'failed',
            'clicked',
            'notclicked',
        ]
    }

    get availableEmailFrom () {
        return [
            'sent',
            'opened',
            'clicked',
            'notopened',
            'notclicked',
        ]
    }

    get selectedDateCustomField () {
        return WorkflowsModule.selectedDateCustomField?.name || ''
    }

    get prevMessage () {
        if (!this.workflow || !this.workflowMessage || this.workflow.trigger_name === 'onDate') return null

        return this.workflow.messages?.data.find((el: WorkflowMessage) => this.workflowMessage && el.priority === this.workflowMessage.priority - 1) || null
    }

    get sentTrigger () {
        if (this.workflowMessage.trigger.parameter.as === 'immediatily') {
            if (this.workflow.trigger_name === 'onDate') {
                return `Lo stesso giorno della data del campo <b>${this.workflow?.settings?.custom_field_id === 0 ? 'Data iscrizione' : this.selectedDateCustomField}</b>`
            } else {
                return `Immediatamente dopo ${this.prevMessage ? 'che il messaggio precedente' : 'la partenza del flusso'} ${this.prevMessage ? this.$t('workflows.messageTrigger.from.' + this.workflowMessage.trigger.parameter.from) : ''}`
            }
        } else {
            if (this.workflow.trigger_name === 'onDate') {
                return `${this.$tc('workflows.messageTrigger.as.' + this.workflowMessage.trigger.parameter.as, this.workflowMessage.trigger.parameter.wait)} ${this.$t('workflows.messageTrigger.type.' + this.workflowMessage.trigger.parameter.type)} della data del campo <b>${this.workflow?.settings?.custom_field_id === 0 ? 'Data iscrizione' : this.selectedDateCustomField}</b>`
            } else {
                return `${this.$tc('workflows.messageTrigger.as.' + this.workflowMessage.trigger.parameter.as, this.workflowMessage.trigger.parameter.wait)} ${this.$t('workflows.messageTrigger.type.' + this.workflowMessage.trigger.parameter.type)} ${this.selectedDateCustomField} ${this.prevMessage ? 'che il messaggio precedente' : 'che il contatto è entrato nella marketing automation'} ${this.prevMessage ? this.$t('workflows.messageTrigger.from.' + this.workflowMessage.trigger.parameter.from) : ''}`
            }
        }
    }

    get specificDayOrHour () {
        let text = ''

        if (this.workflowMessage.trigger.scheduler.days.length < 7 || this.workflowMessage.trigger.scheduler.active) {
            text = 'solo'
        }

        if (this.workflowMessage.trigger.scheduler.days.length < 7) {
            const days: any[] = []
            for (const day of this.workflowMessage.trigger.scheduler.days) {
                days.push(this.$t('workflows.messageTrigger.days.' + day))
            }
            text += ' di ' + days.join(', ')
        }

        if (this.workflowMessage.trigger.scheduler.active) {
            text += ' alle ' + this.workflowMessage.trigger.scheduler.time.hour + ':' + this.workflowMessage.trigger.scheduler.time.minute
        }

        return text
    }

    setContentErrors (hasErrors: boolean) {
        this.hasContentErrors = hasErrors
    }

    @Watch('hasErrors')
    emitErrors () {
        this.$emit('errors', this.hasErrors)
    }
}
