

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import DomainNameModal from '@/modules/campaigns/components/DomainNameModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { Workflow } from '@/api/consoleApi/dto/workflows.dto'
import { updateWorkflow } from '@/api/consoleApi/workflows'
import { WorkflowsModule } from '@/store/modules/workflows'
import { formatNumber } from '@/utils/formatter'
import updateSubscriber from '@/modules/workflows/components/VsWorkflowSettingsCard/updateSubscriber.vue'
import flowEnd from '@/modules/workflows/components/VsWorkflowSettingsCard/flowEnd.vue'
import onDate from '@/modules/workflows/components/VsWorkflowSettingsCard/onDate.vue'
import campaignActivity from '@/modules/workflows/components/VsWorkflowSettingsCard/campaignActivity.vue'
import webSite from '@/modules/workflows/components/VsWorkflowSettingsCard/webSite.vue'
import ecommerceActivityHistory from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceActivityHistory.vue'
import ecommerceOrderCompleted from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceOrderCompleted.vue'
import ecommerceProductPurchased from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceProductPurchased.vue'
import ecommerceCategoryPurchased from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceCategoryPurchased.vue'
import ecommerceBrandPurchased from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceBrandPurchased.vue'
import ecommerceAbbandonedCart from '@/modules/workflows/components/VsWorkflowSettingsCard/ecommerceAbbandonedCart.vue'

@Component({
    name: 'VsWorkflowSettingsCard',
    components: {
        VsCollapseCard,
        VsOverlayCard,
        DomainNameModal,
        updateSubscriber,
        flowEnd,
        onDate,
        campaignActivity,
        webSite,
        ecommerceActivityHistory,
        ecommerceOrderCompleted,
        ecommerceProductPurchased,
        ecommerceCategoryPurchased,
        ecommerceBrandPurchased,
        ecommerceAbbandonedCart,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) workflow!: Workflow
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    private loading = false
    settings: any = null

    $refs: any

    openTab () {
        this.$emit('open-tab', 'settings')
    }

    get cardStatus () {
        if (!this.workflow) return 'default'

        switch (this.workflow.trigger_name) {
            case 'flowEnd':
                if (!this.workflow?.settings?.workflow_id) {
                    return 'default'
                } else {
                    return 'success'
                }
            case 'campaignActivity':
                if (!this.workflow?.settings?.campaign_id) {
                    return 'default'
                } else {
                    return 'success'
                }
            case 'webSite':
                if (!this.workflow?.settings?.value) {
                    return 'default'
                } else {
                    return 'success'
                }
            case 'onDate':
                if (this.workflow?.settings?.date_source === 'customField') {
                    return this.workflow?.settings?.custom_field_id ? 'success' : 'error'
                } else {
                    return 'success'
                }
            case 'ecommerceCategoryPurchased':
            case 'ecommerceBrandPurchased':
                return this.workflow?.settings?.value ? 'success' : 'default'
            case 'ecommerceAbbandonedCart':
                if (!this.workflow?.settings?.value) return 'default'
                return this.workflow?.settings?.value < 3 || this.workflow?.settings?.value > 24 ? 'error' : 'success'
            case 'updateSubscriber':
            case 'welcomeMessage':
            case 'ecommerceProductPurchased':
            case 'ecommerceOrderCompleted':
            case 'ecommerceActivityHistory':
            default:
                return 'success'
        }
    }

    get selectedDateCustomField () {
        return WorkflowsModule.selectedDateCustomField
    }

    get selectedDateCustomFieldName () {
        return WorkflowsModule.selectedDateCustomField?.name || ''
    }

    get selectedWorkflow () {
        return WorkflowsModule.selectedWorkflow
    }

    get selectedWorkflowName () {
        return this.selectedWorkflow?.name || 'Non trovato'
    }

    get selectedProduct () {
        return WorkflowsModule.selectedProduct
    }

    get selectedProductName () {
        return this.selectedProduct?.title || 'Non trovato'
    }

    get selectedCampaign () {
        return WorkflowsModule.selectedCampaign
    }

    get selectedCustomFields () {
        return WorkflowsModule.selectedCustomFields
    }

    get selectedCustomFieldsNames () {
        return WorkflowsModule.selectedCustomFields.map(el => el.name)
    }

    get selectedCampaignName () {
        return this.selectedCampaign?.name || 'Non trovata'
    }

    get triggerNameWithNoSettings () {
        return this.workflow.trigger_name === 'welcomeMessage'
    }

    get humanEventStart () {
        if (!this.workflow || !this.workflow.recipient) return ''
        let messageCond = ''

        switch (this.workflow.trigger_name) {
            case 'updateSubscriber':
                if (!this.workflow?.settings?.custom_field_ids || this.workflow?.settings?.custom_field_ids?.length === 0) {
                    messageCond = 'quando avviene la modifica di un qualsiasi campo del contatto'
                    break
                }
                if (this.workflow?.settings?.custom_field_ids?.length === 1) {
                    messageCond = `quando avviene la modifica del campo <b>${this.selectedCustomFieldsNames[0]}</b> del contatto`
                } else {
                    messageCond = `quando avviene la modifica del campo <b>${this.selectedCustomFieldsNames.join(this.workflow?.settings?.condition === 'all' ? '</b>, e del campo <b>' : '</b>, o del campo <b>')}</b> del contatto`
                }
                break
            case 'welcomeMessage':
                messageCond = `quando il contatto si iscrive alla lista <b>${this.workflow?.recipient?.data?.name || ''}</b>`
                break
            case 'flowEnd':
                if (!this.workflow?.settings?.workflow_id) {
                    messageCond = `quando il contatto termina un'altra Marketing Automation: Scegline una`
                } else {
                    messageCond = `quando il contatto termina la marketing automation: <b>${this.selectedWorkflowName}</b>`
                }
                break
            case 'campaignActivity':
                if (!this.workflow?.settings?.campaign_id) {
                    messageCond = 'quando un contatto apre o clicca una campagna. Modifica per selezionare una campagna'
                    break
                }
                if (this.workflow?.settings?.activity_type === 'open') {
                    messageCond = `quando un contatto apre la campagna <b>${this.selectedCampaignName}</b>`
                } else {
                    messageCond = this.workflow?.settings?.value ? `quando un contatto clicca il link <b>${this.workflow?.settings?.value || ''}</b> della campagna <b>${this.selectedCampaignName}</b>` : `quando un contatto clicca un link qualsiasi della campagna <b>${this.selectedCampaignName}</b>`
                }
                break
            case 'onDate':
                messageCond = `in base alla data del campo <b>${this.workflow?.settings?.custom_field_id === 0 ? 'Data iscrizione' : this.selectedDateCustomFieldName}</b>${this.workflow?.settings?.repeat_annually ? '. Viene ripetuto ogni anno' : ''}`
                break
            case 'webSite':
                if (this.workflow?.settings?.type === 'event') {
                    messageCond = this.workflow?.settings?.value ? `quando un contatto scatena l' evento <b>${this.workflow?.settings?.value}</b> sul mio sito` : 'quando un contatto scatena un evento sul mio sito. Inserisci un evento'
                } else {
                    messageCond = this.workflow?.settings?.value ? `quando un contatto visita la pagina <b>${this.workflow?.settings?.value}</b>` : 'quando un contatto visita una pagina del mio sito. Inserisci l\' url della pagina'
                }
                break
            case 'ecommerceAbbandonedCart':
                messageCond = `quando il carrello resta abbandonato per almeno <b>${this.$tc('workflows.workflowCard.triggerName.ecommerceAbbandonedCart.settings.value', this.workflow?.settings?.value || 3)}</b>`
                break
            case 'ecommerceProductPurchased':
                if (this.workflow?.settings?.value) {
                    messageCond = `quando viene acquistato il prodotto <b>${this.selectedProductName}</b>`
                } else {
                    messageCond = `quando viene acquistato un prodotto qualsiasi`
                }
                break
            case 'ecommerceCategoryPurchased':
                if (this.workflow?.settings?.value) {
                    messageCond = `quando viene acquistato un prodotto con la categoria <b>${this.workflow?.settings?.value}</b>`
                } else {
                    messageCond = `quando viene acquistato un prodotto con la categoria: Selezionane una`
                }
                break
            case 'ecommerceBrandPurchased':
                if (this.workflow?.settings?.value) {
                    messageCond = `quando viene acquistato un prodotto con il brand <b>${this.workflow?.settings?.value}</b>`
                } else {
                    messageCond = `quando viene acquistato un prodotto con il brand: Selezionane uno`
                }
                break
            case 'ecommerceOrderCompleted':
                if (this.workflow?.settings?.value === 0) {
                    messageCond = `quando viene concluso un ordine qualsiasi`
                } else {
                    messageCond = `quando viene concluso un ordine con il prezzo totale ${this.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.' + this.workflow?.settings?.operator)} ${formatNumber(this.workflow?.settings?.value || 0)}`
                }
                break
            case 'ecommerceActivityHistory':
                messageCond = `quando viene effettuato un ordine ed il contatto ha un ${this.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.type.' + this.workflow?.settings?.type)} ${this.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.operator.' + this.workflow?.settings?.operator)} ${formatNumber(this.workflow?.settings?.value || 0)} ${this.$t('workflows.workflowCard.triggerName.ecommerceActivityHistory.settings.period.' + this.workflow?.settings?.period)}`
                break
        }

        return messageCond
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    private openTabAdvanced () {
        this.settings = JSON.parse(JSON.stringify(this.workflow.settings))
        this.openTab()
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    async save () {
        const valid = await this.$refs.settingsValidation.validate()
        if (!valid) return
        this.loading = true
        try {
            await updateWorkflow(this.workflow?.id, {
                settings: this.settings,
            })
            this.$emit('settings-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }
}
