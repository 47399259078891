





































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import DomainNameModal from '@/modules/campaigns/components/DomainNameModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import VsMessageModal from '@/components/VsMessageModal/Index.vue'
import VsMessageTriggerModal from '@/modules/workflows/components/VsMessageTriggerModal/Index.vue'
import VsMessageSegmentsModal from '@/modules/workflows/components/VsMessageSegmentsModal/Index.vue'
import { getEmailPreview } from '@/api/consoleApi/contents/emails'
import { getSmsPreview } from '@/api/consoleApi/contents/sms'
import VsWorkflowMessageCard from '@/modules/workflows/components/VsWorkflowMessageCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { Workflow, WorkflowMessage } from '@/api/consoleApi/dto/workflows.dto'
import { addWorkflowMessage, deleteWorkflowMessage, duplicateWorkflowMessage, switchWorkflowMessage } from '@/api/consoleApi/workflows'
import { get, orderBy } from 'lodash'
import { WorkflowsModule } from '@/store/modules/workflows'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { downloadHtml, downloadText } from '@/utils/export'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'

@Component({
    name: 'VsWorkflowTimelineCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsOverlayCard,
        DomainNameModal,
        VsMessageModal,
        VsMessageTriggerModal,
        VsMessageSegmentsModal,
        VsDropdownButton,
        VsPreviewModal,
        VsWorkflowMessageCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) workflow!: Workflow
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    private loading = false
    errors: any[] = []
    $refs: any

    tabName = 'timeline'

    openTab () {
        this.$emit('open-tab', this.tabName)
    }

    get workflowTimelineStatus () {
        if (this.messages.length === 0) return 'default'
        return this.errors.length > 0 ? 'error' : 'success'
    }

    get messages () {
        return this.workflow?.messages?.data || []
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    private openTabAdvanced () {
        this.openTab()
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    async save () {
        return ''
    }

    orderBy (arr: any[], prop: string, order: 'asc' | 'desc') {
        return orderBy(arr, prop, order)
    }

    get beforeMessages () {
        return orderBy(
            this.messages.filter(el => el.trigger.parameter.type === 'before' && el.trigger.parameter.as !== 'immediatily'),
            'hourDelay',
            'desc',
        )
    }

    get afterMessages () {
        return orderBy(
            this.messages.filter(el => el.trigger.parameter.type === 'after' || el.trigger.parameter.as === 'immediatily'),
            this.workflow.trigger_name === 'onDate' ? 'hourDelay' : 'priority',
            'asc',
        )
    }

    get selectedDateCustomField () {
        return WorkflowsModule.selectedDateCustomField?.name || ''
    }

    async editMessageModal (message: any) {
        await this.$refs.vsMessageModal.openModal({
            messageId: message.entity_id,
            contentType: message.entity_type,
            containerId: this.workflow.id,
            containerType: 'automation',
            title: message.name,
            listId: this.workflow?.recipient?.data?.id || 0,
            workflowMessageId: message.id,
        })
    }

    async addMessage (entity_type: 'email' | 'sms') {
        if (!this.workflow) return ''
        this.loading = true
        try {
            const resp = await addWorkflowMessage(
                this.workflow.id,
                {
                    entity_type,
                    name: 'Messaggio senza nome',
                },
            )
            this.$emit('messages-saved')
            await this.editMessageModal(resp.data.data)
        } catch (e) {
            let message = ''
            const error = get(e, 'response.data.error', '')
            if (error === 'WorkflowLimitError') {
                message = 'Non puoi creare più di 20 messaggi per Marketing automation'
            }
            this.$root.$vsToast({
                timeout: 3000,
                message,
                heading: 'Errore durante l\'aggiunta del messaggio',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteMessage (messageId: any) {
        if (!this.workflow) return ''
        this.loading = true
        try {
            await deleteWorkflowMessage(this.workflow.id, messageId)
            const index = this.errors.indexOf(messageId)
            if (index > -1) {
                this.errors.splice(index, 1)
            }
            this.$emit('messages-saved')
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    atSave () {
        this.$emit('messages-saved')
    }

    setErrors (messageId: any, hasErrors: boolean) {
        const index = this.errors.indexOf(messageId)
        if (hasErrors && index === -1) {
            this.errors.push(messageId)
        }
        if (!hasErrors && index > -1) {
            this.errors.splice(index, 1)
        }
    }

    async switchMessages (workflowId: number, switchWorkflowId: number) {
        this.loading = true
        try {
            await switchWorkflowMessage(this.workflow.id, workflowId, switchWorkflowId)
            this.$emit('messages-saved')
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private async exportHtml (message: WorkflowMessage) {
        try {
            const resp = await getEmailPreview(message.entity_id)
            downloadHtml(resp.data, message.name)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'export dell\'html',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async exportSms (message: WorkflowMessage) {
        try {
            const resp = await getSmsPreview(message.entity_id)
            downloadText(resp.data.body, message.name)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'export dell\'sms',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private openPreviewModal (message: WorkflowMessage) {
        this.$refs.previewModal.openModalNew(message[message.entity_type]?.data, message.entity_type)
    }

    async duplicateMessage (messageId: number) {
        this.loading = true
        try {
            await duplicateWorkflowMessage(this.workflow.id, messageId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Messaggio duplicato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('messages-saved')
        } catch (e) {
            console.log(e)
            let message = ''
            const error = get(e, 'response.data.error', '')
            if (error === 'WorkflowLimitError') {
                message = 'Non puoi creare più di 20 messaggi per Marketing automation'
            }
            this.$root.$vsToast({
                timeout: 3000,
                message,
                heading: 'Errore durante la duplicazione del messaggio',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
